import { Section } from '@scale/scaleui-radix';
import styles from './DocsPageWrapper.module.css';

export function DocsPageWrapper({
  style,
  ...props
}: React.ComponentPropsWithoutRef<typeof Section>) {
  return <div className={styles.DocsPageWrapper}>
    <Section
      px={{ initial: '5', xs: '6', sm: '7', md: '9' }}
      size={{ initial: '2', md: '4' }}
      style={{ maxWidth: 900, ...style }}
      width="100%"
      {...props}
    />
  </div>
}
