import { GitHubLogoIcon } from '@radix-ui/react-icons';
import { Box, ScrollArea } from '@scale/scaleui-radix';
import { themesRoutes } from '@utils/themesRoutes';
import { DocsNav } from './DocsNav';
import { MobileMenu } from './MobileMenu';
import { ThemesHeader } from './ThemesHeader';

export function ThemesMobileMenu() {
  return <MobileMenu>
    <ThemesHeader />
    <ScrollArea>
      <Box pb="9" pt="4" px="3">
        <DocsNav
          routes={[
            {
              pages: [
                {
                  title: 'Homepage',
                  slug: '',
                },
                {
                  title: 'Catalog',
                  slug: 'themes/catalog',
                },
              ],
            },
            ...themesRoutes,
            {
              label: 'Resources',
              pages: [
                {
                  title: 'GitHub',
                  slug: 'https://github.com/radix-ui/themes',
                  icon: <GitHubLogoIcon />,
                },
              ],
            },
          ]}
        />
      </Box>
    </ScrollArea>
  </MobileMenu>
}
