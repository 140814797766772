import { Badge, Box, Flex, Heading, Text } from '@scale/scaleui-radix';
import NextLink from 'next/link';
import React from 'react';
import scrollIntoView from 'scroll-into-view-if-needed';
import { useCurrentPageSlug } from '@utils/useCurrentPageSlug';
import { classNames } from '@utils/classNames';
import styles from './DocsNav.module.css';

type DocsNavProps = {
  routes: {
    label?: string;
    pages: {
      title: string;
      slug: string;
      icon?: React.ReactNode;
      preview?: boolean;
      deprecated?: boolean;
    }[];
  }[];
};

export function DocsNav({ routes }: DocsNavProps) {
  const currentPageSlug = useCurrentPageSlug();

  return (
    <Box>
      {routes.map((section, i) => (
        <Box key={section.label ?? i} mb="4">
          {section.label ? (
            <Box px="3" py="2">
              <Heading as="h4" size={{ initial: '3', md: '2' }}>
                {section.label}
              </Heading>
            </Box>
          ) : null}

          {section.pages.map(page => (
            <DocsNavItem active={currentPageSlug === page.slug} href={page.slug} key={page.slug}>
              <Flex align="center" gap="2">
                {page.icon}
                <Text size={{ initial: '3', md: '2' }}>{page.title}</Text>
              </Flex>

              {page.preview ? (
                <Badge color="gray" ml="2" radius="full" variant="surface">
                  Preview
                </Badge>
              ) : null}

              {page.deprecated ? (
                <Badge color="gray" ml="2" radius="full" variant="surface">
                  Deprecated
                </Badge>
              ) : null}
            </DocsNavItem>
          ))}
        </Box>
      ))}
    </Box>
  );
}

type DocsNavItemProps = {
  children: React.ReactNode;
  active?: boolean;
  disabled?: boolean;
  href: string;
  className?: string;
};

function DocsNavItem({ active, disabled, href, children, ...props }: DocsNavItemProps) {
  const className = classNames(styles.DocsNavItem, active && styles.active);
  const isExternal = href.startsWith('http');
  const ref = React.useRef<HTMLAnchorElement>(null);

  React.useEffect(() => {
    // Scroll active links into view when in a Scroll Area
    if (ref.current && active) {
      const container = document.querySelector('[data-radix-scroll-area-viewport]');

      if (!container) {
        return;
      }

      // Tread very, very, very carefully if changing this.
      // Sneaky bugs reproduced only on select cursed devices may show up.
      scrollIntoView(ref.current, {
        block: 'nearest',
        scrollMode: 'if-needed',
        boundary: parent => Boolean(container.contains(parent)),
        behavior: actions => {
          actions.forEach(({ el, top }) => {
            const dir = el.scrollTop < top ? 1 : -1;
            el.scrollTop = top + 80 * dir;
          });
        },
      });
    }
  }, [active]);

  if (disabled) {
    return (
      <span className={className} ref={ref} {...props}>
        {children}
      </span>
    );
  }

  if (isExternal) {
    return (
      <a className={className} href={href} ref={ref} rel="noopener" target="_blank" {...props}>
        {children}
      </a>
    );
  }

  return (
    <NextLink href={`/${href}`} legacyBehavior passHref>
      <a className={className} ref={ref} {...props}>
        {children}
      </a>
    </NextLink>
  );
}
