import '@fortawesome/fontawesome-svg-core/styles.css';
import './styles/index.css';
import './styles/syntax-highlighting.css';

import { Theme } from '@scale/scaleui-radix';
import { ThemeProvider } from 'next-themes';
import type { AppProps } from 'next/app';
import { useRouter } from 'next/router';
import { ThemesDocsPage } from '@components/ThemesDocsPage';
import { Favicon } from '@components/Favicon';
import { CssLibPreferenceProvider } from '@components/CssLibPreference';

function Pages({ Component, pageProps }: AppProps) {
  const router = useRouter();

  if (router.pathname.startsWith('/themes/docs')) {
    return (
      <Theme>
        <ThemesDocsPage>
          <Favicon />
          <Component {...pageProps} />
        </ThemesDocsPage>
      </Theme>
    );
  }

  if (router.pathname.startsWith('/themes/catalog')) {
    return (
      <Theme>
        <Favicon />
        <Component {...pageProps} />
      </Theme>
    );
  }

  if (router.pathname.startsWith('/themes')) {
    return (
      <Theme>
        <Favicon />
        <Component {...pageProps} />
      </Theme>
    );
  }

  if (router.pathname.startsWith('/blog')) {
    return (
      <Theme>
        <Favicon />
        <Component {...pageProps} />
      </Theme>
    );
  }

  return (
    <Theme>
      <Favicon />
      <Component {...pageProps} />
    </Theme>
  );
}

function App(props: AppProps) {
  return (
    <CssLibPreferenceProvider>
      <ThemeProvider
        attribute="class"
        disableTransitionOnChange
        enableSystem={false}
        value={{
          light: 'light-theme',
          dark: 'dark-theme',
          duneLight: 'light-theme',
          duneDark: 'dark-theme',
          contributorsDark: 'dark-theme',
          contributorsLight: 'light-theme',
        }}
      >
        <Pages {...props} />
      </ThemeProvider>
    </CssLibPreferenceProvider>
  );
}

export default App;
