import { Box, Flex, Link, Text } from '@scale/scaleui-radix';
import NextLink from 'next/link';
import { useCurrentPageSlug } from '@utils/useCurrentPageSlug';

export function DocsPagination({ allRoutes }) {
  const currentPageSlug = useCurrentPageSlug();
  const currentPageIndex = allRoutes.findIndex(page => page.slug === currentPageSlug);
  const previous = allRoutes[currentPageIndex - 1];
  const next = allRoutes[currentPageIndex + 1];

  return (
    <Box>
      {(previous || next) ? <Flex aria-label="Pagination navigation" justify="between" my="8">
          {previous ? <DocsPaginationLink direction="Previous" route={previous} /> : null}
          {next ? <Box flexGrow="1" style={{ textAlign: 'right' }}>
              <DocsPaginationLink direction="Next" route={next} />
            </Box> : null}
        </Flex> : null}
    </Box>
  );
}

function DocsPaginationLink({ route, direction }) {
  return (
    <Flex
      aria-label={`${direction} page: ${route.title}`}
      direction="column"
      display="inline-flex"
      gap="1"
    >
      <Text color="gray" size="2">
        {direction}
      </Text>
      <NextLink href={`/${route.slug}`} legacyBehavior passHref>
        <Link>
          <Text size="4">{route.title}</Text>
        </Link>
      </NextLink>
    </Flex>
  );
}
