import * as React from 'react';
import { classNames } from '@utils/classNames';
import styles from './BoxLink.module.css';

type BoxLinkProps = React.ComponentPropsWithoutRef<'a'>;

export const BoxLink = React.forwardRef<HTMLAnchorElement, BoxLinkProps>(function BoxLink(
  { className, children, ...props },
  forwardedRef,
) {
  return (
    <a className={classNames(styles.BoxLink, className)} ref={forwardedRef} {...props}>
      {children}
    </a>
  );
});
