import { useTheme } from 'next-themes';
import Head from 'next/head';
import { NoSsr } from '@components/NoSsr';

export function Favicon() {
  const { theme } = useTheme();

  return (
    <Head>
      <link href="/favicon.ico" rel="icon" />
      <NoSsr>
        {theme === 'dark' ? (
          <link href="/favicon-white.svg" rel="icon" />
        ) : (
          <link href="/favicon-black.svg" rel="icon" />
        )}
      </NoSsr>
    </Head>
  );
}
